"use client";

/**
 * Third-party libraries.
 */
import { Button, Divider, Layout } from "antd";
import { usePathname, useRouter } from "next/navigation";
import { useMemo } from "react";

/**
 * Project components.
 */
import { AgentList, AgentListProps } from "@/components/client/agent";
import {
  UserAvailabilityStatus,
  useUsersQuery,
} from "@/components/client/graphql";
import { Icon, IconProps } from "@/components/client/icon";
import { useApplicationContext } from "../context";

/**
 * Navigation menu item.
 */
type NavigationMenuItem = {
  /**
   * The icon to display.
   */
  icon: IconProps["src"];
  /**
   * The label to display.
   */
  label: string;
  /**
   * The path to navigate to.
   */
  path: string;
};

/**
 * Menu items that show in the sidebar.
 * Used to navigate to different pages.
 */
const navigationMenu: NavigationMenuItem[] = [
  {
    icon: "inbox",
    label: "Team Inbox",
    path: "/",
  },
  // {
  //   icon: "chart",
  //   label: "Analytics",
  //   path: "/analytics",
  // },
];

/**
 *
 * Global layout for all pages, include sidebar and content areas. Children will displayed in content area.
 * Requires authentication to access this component.
 */
export function DefaultProtectedPageLayoutSidebar() {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  const router = useRouter();
  const pathName = usePathname();

  const { mockData } = useApplicationContext();

  const {
    data: usersResponse,
    error: usersError,
    loading: usersLoading,
  } = useUsersQuery();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const agentList = useMemo<AgentListProps["agents"]>(() => {
    if (!usersResponse || usersLoading) {
      return [];
    }
    if (mockData) {
      return [
        {
          name: "John Doe",
          status: UserAvailabilityStatus.Available,
          statusDateTime: new Date(),
        },
        {
          name: "Jane Smith",
          status: UserAvailabilityStatus.Break,
          statusDateTime: new Date(),
        },
        {
          name: "Michael Johnson",
          status: UserAvailabilityStatus.OnACall,
          statusDateTime: new Date(),
        },
        {
          name: "Emily Brown",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "David Wilson",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "Sarah Johnson",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "Robert Davis",
          status: UserAvailabilityStatus.Break,
          statusDateTime: new Date(),
        },
        {
          name: "Olivia Wilson",
          status: UserAvailabilityStatus.OnACall,
          statusDateTime: new Date(),
        },
        {
          name: "James Brown",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "Sophia Smith",
          status: UserAvailabilityStatus.Available,
          statusDateTime: new Date(),
        },
        {
          name: "William Johnson",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "Emma Davis",
          status: UserAvailabilityStatus.Break,
          statusDateTime: new Date(),
        },
        {
          name: "Alexander Wilson",
          status: UserAvailabilityStatus.OnACall,
          statusDateTime: new Date(),
        },
        {
          name: "Mia Brown",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "Ethan Smith",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "Ava Johnson",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
      ];
    }

    if (mockData) {
      return [
        {
          name: "John Doe",
          status: UserAvailabilityStatus.Available,
          statusDateTime: new Date(),
        },
        {
          name: "Jane Smith",
          status: UserAvailabilityStatus.Break,
          statusDateTime: new Date(),
        },
        {
          name: "Michael Johnson",
          status: UserAvailabilityStatus.OnACall,
          statusDateTime: new Date(),
        },
        {
          name: "Emily Brown",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "David Wilson",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "Sarah Johnson",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "Robert Davis",
          status: UserAvailabilityStatus.Break,
          statusDateTime: new Date(),
        },
        {
          name: "Olivia Wilson",
          status: UserAvailabilityStatus.OnACall,
          statusDateTime: new Date(),
        },
        {
          name: "James Brown",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "Sophia Smith",
          status: UserAvailabilityStatus.Available,
          statusDateTime: new Date(),
        },
        {
          name: "William Johnson",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "Emma Davis",
          status: UserAvailabilityStatus.Break,
          statusDateTime: new Date(),
        },
        {
          name: "Alexander Wilson",
          status: UserAvailabilityStatus.OnACall,
          statusDateTime: new Date(),
        },
        {
          name: "Mia Brown",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "Ethan Smith",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
        {
          name: "Ava Johnson",
          status: UserAvailabilityStatus.Offline,
          statusDateTime: new Date(),
        },
      ];
    }

    return (usersResponse.users ?? []).map((user) => ({
      name: user.profile.fullName,
      status: user.availability.status,
      statusDateTime: new Date(user.availability.updatedDate),
    }));
  }, [mockData, usersLoading, usersResponse]);

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  return (
    <Layout.Sider
      className="!bg-white h-full flex flex-col !gap-3 !py-5 !px-4"
      width={220}
      style={{
        borderRight: "1px solid rgba(0,0,0,0.1)",
      }}
    >
      <div className="h-full w-full flex flex-col gap-3">
        <div className="flex flex-col gap-3 w-full leading-7">
          {navigationMenu.map((navigationMenuItem) => (
            <Button
              className="flex items-start p-4 w-full"
              key={navigationMenuItem.path}
              onClick={() => router.push(navigationMenuItem.path)}
              size="large"
              style={{
                fontSize: "0.875rem",
                justifyContent: "flex-start",
              }}
              type={pathName === navigationMenuItem.path ? "primary" : "text"}
            >
              <div className="flex items-center gap-2">
                <Icon src={navigationMenuItem.icon} height={16} width={16} />
                <div>{navigationMenuItem.label}</div>
              </div>
            </Button>
          ))}
        </div>
        <Divider className="!m-0" />
        <div className="w-full overflow-y-auto">
          <AgentList
            agents={agentList}
            loading={usersLoading}
            // loading
          />
        </div>
      </div>
    </Layout.Sider>
  );
}
