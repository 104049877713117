"use client";

/**
 * Third-party libraries.
 */
import { Button, Dropdown, Layout, MenuProps, Select, Switch } from "antd";
import Link from "next/link";
import { useLayoutEffect, useState } from "react";

/**
 * Project components.
 */
import { useAuthenticationContext } from "@/components/client/authentication";
import { UserAvatar } from "@/components/client/avatar";
import { UserAvailabilityStatus } from "@/components/client/graphql";
import { Icon } from "@/components/client/icon";
import { ThePiqueLabLogo } from "@/components/client/images";
import { Loading } from "@/components/client/loading";
import { ApiRoute } from "@/components/client/route";
import { Auth0Permission } from "@/components/common/auth0/enumerations";
import { StringUtility } from "@/components/common/string/utilities";
import { useRouter } from "next/navigation";
import { useApplicationContext } from "../context";
import { useTwilioContext } from "../twilio";

/**
 * Default protected page layout header properties.
 */
type DefaultProtectedPageLayoutHeaderProps = {
  // /**
  //  * Callback when the user availability status changes.
  //  */
  // onUserAvailabilityStatusChange?: (args: {
  //   /**
  //    * User availability status.
  //    */
  //   status: UserAvailabilityStatus;
  // }) => void;
  // /**
  //  * User availability status.
  //  */
  // userAvailabilityStatus?: UserAvailabilityStatus;
};

/**
 *
 * Global layout for all pages, include sidebar and content areas. Children will displayed in content area.
 * Requires authentication to access this component.
 */
export function DefaultProtectedPageLayoutHeader({}: DefaultProtectedPageLayoutHeaderProps) {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================
  const router = useRouter();
  /**
   * Logged on user details.
   */
  const { user, error, fetching } = useAuthenticationContext();

  /**
   * Application Context.
   */
  const {
    mockData,
    setMockData,
    setShowDialer,
    setUserAvailabilityStatus,
    showDialer,
    updatingUserAvailabilityStatus,
    userAvailabilityStatus,
  } = useApplicationContext();

  /**
   * Twilio Context.
   */
  const { device, registering } = useTwilioContext();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * User initials.
   */
  const [userInitials, setUserAvatarInitials] = useState<string | null>();

  /**
   * User avatar menu items.
   */
  const [userAvatarMenuItems, setUserAvatarMenuItems] =
    useState<MenuProps["items"]>();

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Set the user avatar initials and menu items.
   */
  useLayoutEffect(() => {
    // Get the user initials.
    setUserAvatarInitials(
      StringUtility.getInitials({ input: user?.name ?? "", maxLength: 2 })
    );

    /**
     * Create the drop down menu for the user avatar.
     */
    setUserAvatarMenuItems([
      {
        key: "1",
        disabled: true,
        label: (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.antgroup.com"
          >
            {user?.name}
          </Link>
        ),
      },
      {
        key: "2",
        label: (
          <Link href={ApiRoute.LOGOUT} prefetch={false}>
            Logout
          </Link>
        ),
      },
    ]);
  }, [router, setUserAvailabilityStatus, user]);

  if (fetching) {
    return <Loading size="large" />;
  }

  if (error) return <div>{error.message}</div>;

  if (!user) {
    router.push(ApiRoute.LOGIN);

    return <Loading size="large" />;
  }

  if (user && !user.permissions.includes(Auth0Permission.APPLICATION_ACCESS)) {
    router.push("/401");

    return <Loading size="large" />;
  }

  return (
    <Layout.Header
      className="flex justify-between"
      style={{
        border: "1px solid rgba(0,0,0,0.1)",
      }}
    >
      <ThePiqueLabLogo />
      <div className="flex items-center gap-2">
        <Switch
          checkedChildren="Live"
          unCheckedChildren="Demo"
          onChange={(checked) => {
            setMockData(!checked);
          }}
          value={!mockData}
        />
        <Button
          type="text"
          icon={<Icon src="dial-pad" />}
          onClick={() => setShowDialer(!showDialer)}
        />
        <Button type="text" icon={<Icon src="gear" />} />
        <Select
          className="user-availability-status-selector"
          disabled={
            !device ||
            updatingUserAvailabilityStatus ||
            registering ||
            userAvailabilityStatus === UserAvailabilityStatus.OnACall
          }
          loading={updatingUserAvailabilityStatus || registering}
          options={[
            {
              label: "Available",
              value: UserAvailabilityStatus.Available,
            },
            {
              label: "Break",
              value: UserAvailabilityStatus.Break,
            },
            {
              label: "Busy",
              value: UserAvailabilityStatus.Busy,
            },
            {
              label: "Offline",
              value: UserAvailabilityStatus.Offline,
            },
            {
              disabled: true,
              label: "On A Call",
              value: UserAvailabilityStatus.OnACall,
            },
          ]}
          onChange={(value) => {
            setUserAvailabilityStatus({ status: value, isDefault: true });
          }}
          style={{
            minWidth: 120,
          }}
          value={userAvailabilityStatus}
        />
        <Dropdown
          menu={{ items: userAvatarMenuItems }}
          placement="bottomLeft"
          trigger={["click"]}
        >
          <div>
            <UserAvatar
              status={userAvailabilityStatus}
              initials={userInitials ?? undefined}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
        </Dropdown>
      </div>
    </Layout.Header>
  );
}
