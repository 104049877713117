/**
 * Third-party libraries.
 */
import { UserOutlined } from "@ant-design/icons";
import { Avatar, AvatarProps, Badge, BadgeProps } from "antd";
import { useMemo } from "react";

/**
 * Project components.
 */
import { UserAvailabilityStatus } from "@/components/client/graphql";

/**
 * Properties of the user avatar component.
 */
export type UserAvatarProps = {
  /**
   * Initials of the user to display. A user icon will be displayed if this is not provided or is null.
   * This can't be more than two (2) characters.
   */
  initials?: string;
  /**
   * Callback when the user avatar is clicked.
   */
  onClick?: AvatarProps["onClick"];
  /**
   * Status of the user. Displays the corresponding badge color.
   */
  status?: UserAvailabilityStatus;
  /**
   * Additional properties for the avatar component.
   */
  style?: AvatarProps["style"];
};

/**
 * Displays a user avatar.
 */
export default function UserAvatar({
  initials,
  onClick,
  status = UserAvailabilityStatus.Offline,
  style,
}: UserAvatarProps) {
  if ((initials?.length ?? 0) > 2) {
    throw Error("Initials can't be more than two (2) characters.");
  }

  /**
   * Badge status to display the corresponding color.
   */
  const badgeStatus = useMemo<BadgeProps["status"]>(() => {
    switch (status) {
      case UserAvailabilityStatus.Available:
        return "success";
      case UserAvailabilityStatus.Break:
        return "warning";
      case UserAvailabilityStatus.Busy:
        return "default";
      case UserAvailabilityStatus.OnACall:
        // The color of this status will be overriden below by the "badgeColorOverride".
        return "processing";
      case UserAvailabilityStatus.Offline:
        return "default";
      default:
        throw Error("User status not supported.");
    }
  }, [status]);

  /**
   * This will override the "processing" status of the badge to make it red.
   */
  const badgeColorOverride =
    status === UserAvailabilityStatus.Busy ? "red" : undefined;

  const avatarStyle = {
    background: "#f0f1f9",
    color: "#4f537b",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "125%",
  };

  return (
    <Badge
      color={badgeColorOverride}
      dot
      status={badgeStatus}
      offset={[-3, 28]}
    >
      <div
        className="relative"
        style={{
          height: 32,
          width: 32,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!!initials ? (
          <Avatar
            className="user-avatar"
            onClick={onClick}
            style={{ ...avatarStyle, ...style }}
          >
            {initials}
          </Avatar>
        ) : (
          <Avatar
            icon={<UserOutlined />}
            onClick={onClick}
            style={{ ...avatarStyle, ...style }}
          />
        )}
      </div>
    </Badge>
  );
}
