import { PageRoute } from "@/components/client/route/page-routes";
import { ComponentType, useEffect } from "react";
import { useAuthenticationContext } from "../authentication/authentication-context";
import { Loading } from "../loading";

/**
 * Options to customize the withPageAuthRequired higher order component.
 *
 * @category Client
 */
export interface WithPageAuthRequiredOptions {
  /**
   * ```js
   * withPageAuthRequired(Profile, {
   *   returnTo: '/profile'
   * });
   * ```
   *
   * Add a path to return the user to after login.
   * @defaultValue "/"
   */
  returnTo?: string;
  /**
   * Url to fallback to when there is no user. That url should be for non-authorized only.
   *
   * @defaultValue "/401"
   */
  fallbackTo?: string;
  /**
   * ```js
   * withPageAuthRequired(Profile, {
   *   onRedirecting: () => <div>Redirecting you to the login...</div>
   * });
   * ```
   *
   * Render a message to show that the user is being redirected to the login.
   */
  onRedirect?: () => JSX.Element;
  /**
   * ```js
   * withPageAuthRequired(Profile, {
   *   onError: error => <div>Error: {error.message}</div>
   * });
   * ```
   *
   * Render a fallback in case of error fetching the user from the profile API route.
   */
  onError?: (error: Error) => JSX.Element;
}

/**
 * ```js
 * const MyProtectedPage = withPageAuthRequired(MyPage);
 * ```
 *
 * When you wrap your pages in this higher order component and an anonymous user visits your page,
 * they will be redirected to the login page and then returned to the page they were redirected from (after login).
 *
 * @category Client
 */
export type WithPageAuthRequired = <P extends {}>(
  /**
   * React's component type needed by this Higher-Order-Component (HOC).
   * @example withPageAuthRequired(Component)
   */
  Component: ComponentType<P>,
  options?: WithPageAuthRequiredOptions
) => React.FC<P>;

export const withPageAuthRequired: WithPageAuthRequired = (
  Component,
  options = {}
) => {
  return function WithPageAuthRequired(props): JSX.Element {
    const {
      fallbackTo,
      returnTo, // TODO: Add defaults.
      onRedirect = () => <Loading size="large" />,
      onError = (error) => <div>Error: {error.message}</div>, // TODO: Turn this into a component that we can use as default.
    } = options;

    const { user, error, fetching } = useAuthenticationContext();

    // If user exists and no errors, redirect them to the returnTo or "/" path.
    useEffect(() => {
      /** URL to go to when no user. */
      const fallbackUrl = fallbackTo ?? PageRoute.Unauthorized;

      // Make sure to not redirect when we're fetching OR a user exists and we're done fetching.
      if (fetching || (user && !fetching)) return;

      let returnToPath: string;

      if (!returnTo) {
        const currentLocation = window.location.toString();
        returnToPath =
          currentLocation.replace(new URL(currentLocation).origin, "") ||
          PageRoute.Dashboard;
      } else {
        returnToPath = returnTo;
      }

      window.location.assign(
        `${fallbackUrl}?returnTo=${encodeURIComponent(returnToPath)}`
      );
    }, [user, fetching, returnTo, fallbackTo]);

    if (error) return onError(error);
    if (user) return <Component user={user} {...(props as any)} />;

    return onRedirect();
  };
};
