"use client";

/**
 * Third-party libraries.
 */
import { withPageAuthRequired } from "@/components/client/hoc/with-page-auth-required";
import { Layout } from "antd";
import { PropsWithChildren, ReactNode } from "react";

/**
 * Project components.
 */
import { useAuthenticationContext } from "@/components/client/authentication";
import { Loading } from "@/components/client/loading";
import { ApiRoute } from "@/components/client/route";
import { Auth0Permission } from "@/components/common/auth0/enumerations";

type DefaultProtectedPageLayoutProps = PropsWithChildren<{
  header?: ReactNode;
  sidebar?: ReactNode;
}>;

/**
 *
 * Global layout for all pages, include sidebar and content areas. Children will displayed in content area.
 * Requires authentication to access this component.
 */
export const DefaultProtectedPageLayout = withPageAuthRequired(function ({
  children,
  header,
  sidebar,
}: DefaultProtectedPageLayoutProps) {
  /**
   * Logged on user details.
   */
  const { user, error, fetching } = useAuthenticationContext();

  if (fetching) {
    return <Loading size="large" />;
  }

  if (error) return <div>{error.message}</div>;

  if (!user) {
    window.location.href = ApiRoute.LOGIN;
    return <Loading size="large" />;
  }

  if (user && !user.permissions.includes(Auth0Permission.APPLICATION_ACCESS)) {
    window.location.href = "/401";
    return <Loading size="large" />;
  }

  return (
    <Layout className="h-full w-full flex-col">
      {header}
      <Layout>
        {sidebar}
        <Layout.Content className="p-0 overflow-auto">
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
});
