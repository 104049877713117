import React, { useEffect, useState } from "react";
import "./icon.css";

type IconSVG =
  | "caret-down"
  | "chart"
  | "contact"
  | "copy"
  | "dial-pad"
  | "filter"
  | "flag"
  | "gear"
  | "inbox"
  | "microphone"
  | "microphone-mute"
  | "person"
  | "pause"
  | "phone"
  | "phone-down"
  | "phone-incoming"
  | "refresh"
  | "search"
  | "transfer"
  | "the-pique-lab-logo";

/**
 * Properties for the Icon component.
 */
export type IconProps = {
  /**
   * The name (without the extension) of the SVG file to load.
   */
  src: IconSVG;
  /**
   * Additional class name for the icon container.
   */
  className?: string;
  /**
   * The CSS color value of the icon.
   */
  color?: string;
  /**
   * The height and width of the icon.
   */
  height?: string | number | undefined;
  /**
   * The height and width of the icon.
   */
  width?: string | number | undefined;
};

/**
 * Displays an icon using an SVG from the "images" folder.
 */
export const Icon = ({
  src,
  className,
  color,
  width = "24px",
  height = "24px",
}: IconProps) => {
  const [SvgIcon, setSvgIcon] = useState<React.ComponentType<any> | null>(null);

  useEffect(() => {
    const fetchSvg = async () => {
      try {
        const importedSvg = await import(
          `@/components/client/images/${src}.svg`
        );

        setSvgIcon(() => importedSvg.default);
      } catch (error) {
        console.error(`Error loading SVG: ${src}`, error);
      }
    };

    fetchSvg();
  }, [src]);

  if (!SvgIcon) {
    return null;
  }

  return (
    <div
      className={`icon-container ${className}`}
      style={{ color, height, width }}
    >
      <SvgIcon className="icon" style={{ fill: "currentColor" }} />
    </div>
  );
};
